.nav {
    position: relative;
    z-index: 500;
}

.nav__logo {
    position: fixed;
    top: 1.5em;
    left: 0;
    margin: 0;
    display: block;
    font-size: 1.5em;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #fff;
    transition: all 500ms ease;
}

.nav__link {
    padding: 0.7em 1.3em;
    display: block;
    color: inherit;
    text-decoration: none;
}

.nav__link_logo {
    background: rgba(0, 0, 0, 0.7);
}

.nav__link_back::before {
    content: '';
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    top: -2px;
    margin-right: 10px;
    margin-left: -8px;
    background-image: url('./arrow.svg');
    background-size: 10px 10px;
    transform: rotate(180deg);
    background-repeat: no-repeat;
}

.nav__link_back:hover {
    color: #111;
}

.nav__link_back:hover::before {
    filter: invert(100%);
}

.nav__contact {
    position: fixed;
    top: 5em;
    left: 0;
    display: block;
    padding-left: 2px;
    font-size: 1.3em;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
    transition: all 100ms ease;
}

.nav__contact:hover {
    background: #fff;
    color: #111;
    border-color: #999;
}

.nav__arrow {
    position: fixed;
    z-index: 500;
    top: 45%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    padding: 20px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url('./arrow.svg');
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
    opacity: 0.8;
    transition: all 200ms ease;
}

.nav__arrow:hover {
    opacity: 1;
}

.nav__arrow_next {
    right: 2em;
}

.nav__arrow_prev {
    left: 2em;
    transform: translateY(-50%) rotate(180deg);
}

@media (max-width: 1100px) {
    .nav__logo {
        font-size: 1.3em;
    }

    .nav__contact {
        font-size: 1.1em;
    }

    .nav__link_back::before {
        top: 0;
        height: 15px;
    }
}

@media (max-width: 960px) {
    .nav__logo {
        font-size: 1.1em;
    }

    .nav__contact {
        top: 5.2em;
        font-size: 0.9em;
    }

    .nav__link_back::before {
        top: 0px;
        height: 10px;
    }

    .nav__arrow {
        width: 10px;
        height: 10px;
    }

    .nav__arrow_next {
        right: 1em;
    }

    .nav__arrow_prev {
        left: 1em;
    }
}

@media (max-width: 830px) {
    .nav__logo {
        font-size: 0.9em;
        position: relative;
    }

    .nav__link_logo {
        display: inline-block;
    }

    .nav__contact {
        position: relative;
        top: auto;
        display: inline-block;
        margin: 2.2em 0 0 0;
        font-size: 0.75em;
    }

    .nav__link_back::before {
        background-size: 8px 8px;
    }

    .nav__arrow {
        top: auto;
        bottom: 0;
        left: auto;
        right: auto;
        transform: none;
    }

    .nav__arrow_next {
        right: 0;
        border-radius: 20px 0 0 0;
        background-position: 17px 17px;
    }

    .nav__arrow_prev {
        left: 0;
        border-radius: 0 0 0 20px;
        transform: rotate(180deg);
        background-position: 16px 13px;
    }
}