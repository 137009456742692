.loading {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #111;
    background: #f7f7f7;
}

.loading_hide {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
    pointer-events: none;
}

.loading__message {
    position: absolute;

    /* Offset for the height of the loading animation */
    top: calc(50% + 20px);

    /* Tweak to make the text perfectly aligned with the animation */
    left: calc(50% + 1px);

    transform: translate(-50%, -50%);
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
    color: #777;
}