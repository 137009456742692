.home {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.home__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1em 2em;
    font-size: 1.5em;
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
}