.loading {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #111;
    background: #f7f7f7;
}

.loading_hide {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
    pointer-events: none;
}

.loading__message {
    position: absolute;

    /* Offset for the height of the loading animation */
    top: calc(50% + 20px);

    /* Tweak to make the text perfectly aligned with the animation */
    left: calc(50% + 1px);

    -webkit-transform: translate(-50%, -50%);

            transform: translate(-50%, -50%);
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
    color: #777;
}
.nav {
    position: relative;
    z-index: 500;
}

.nav__logo {
    position: fixed;
    top: 1.5em;
    left: 0;
    margin: 0;
    display: block;
    font-size: 1.5em;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #fff;
    transition: all 500ms ease;
}

.nav__link {
    padding: 0.7em 1.3em;
    display: block;
    color: inherit;
    text-decoration: none;
}

.nav__link_logo {
    background: rgba(0, 0, 0, 0.7);
}

.nav__link_back::before {
    content: '';
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    top: -2px;
    margin-right: 10px;
    margin-left: -8px;
    background-image: url(/static/media/arrow.74271bc7.svg);
    background-size: 10px 10px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    background-repeat: no-repeat;
}

.nav__link_back:hover {
    color: #111;
}

.nav__link_back:hover::before {
    -webkit-filter: invert(100%);
            filter: invert(100%);
}

.nav__contact {
    position: fixed;
    top: 5em;
    left: 0;
    display: block;
    padding-left: 2px;
    font-size: 1.3em;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
    transition: all 100ms ease;
}

.nav__contact:hover {
    background: #fff;
    color: #111;
    border-color: #999;
}

.nav__arrow {
    position: fixed;
    z-index: 500;
    top: 45%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 20px;
    height: 20px;
    padding: 20px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url(/static/media/arrow.74271bc7.svg);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
    opacity: 0.8;
    transition: all 200ms ease;
}

.nav__arrow:hover {
    opacity: 1;
}

.nav__arrow_next {
    right: 2em;
}

.nav__arrow_prev {
    left: 2em;
    -webkit-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
}

@media (max-width: 1100px) {
    .nav__logo {
        font-size: 1.3em;
    }

    .nav__contact {
        font-size: 1.1em;
    }

    .nav__link_back::before {
        top: 0;
        height: 15px;
    }
}

@media (max-width: 960px) {
    .nav__logo {
        font-size: 1.1em;
    }

    .nav__contact {
        top: 5.2em;
        font-size: 0.9em;
    }

    .nav__link_back::before {
        top: 0px;
        height: 10px;
    }

    .nav__arrow {
        width: 10px;
        height: 10px;
    }

    .nav__arrow_next {
        right: 1em;
    }

    .nav__arrow_prev {
        left: 1em;
    }
}

@media (max-width: 830px) {
    .nav__logo {
        font-size: 0.9em;
        position: relative;
    }

    .nav__link_logo {
        display: inline-block;
    }

    .nav__contact {
        position: relative;
        top: auto;
        display: inline-block;
        margin: 2.2em 0 0 0;
        font-size: 0.75em;
    }

    .nav__link_back::before {
        background-size: 8px 8px;
    }

    .nav__arrow {
        top: auto;
        bottom: 0;
        left: auto;
        right: auto;
        -webkit-transform: none;
                transform: none;
    }

    .nav__arrow_next {
        right: 0;
        border-radius: 20px 0 0 0;
        background-position: 17px 17px;
    }

    .nav__arrow_prev {
        left: 0;
        border-radius: 0 0 0 20px;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        background-position: 16px 13px;
    }
}
.figure {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.figure__img-container {
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.figure__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.figure__caption-container {
    position: absolute;
    z-index: 300;
    top: auto;
    bottom: 30px;
    left: auto;
    right: 0.7em;
    width: 350px;
    height: auto;
    font-size: 1.3em;
    text-align: right;
}

.figure__caption-content {
    position: absolute;
    bottom: 0;
    right: 0;
}

.figure__caption {
    position: static;
    display: inline;
    padding: 0.45em 0 0.5em;
    line-height: 2.4;
    color: rgba(255, 255, 255, 0.5);
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0.7em 0 0 rgba(0, 0, 0, 0.5), -0.7em 0 0 rgba(0, 0, 0, 0.5);

    /* Required for Firefox, otherwise, box-shadow is
     stripped from individual lines of a paragraph */
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
}

.figure__link {
    color: inherit;
    text-decoration: none;
}

.figure__link:hover {
    color: #fff;
}

/* Image Fade Animation */
.figure__img-animation-enter {
    opacity: 0;
}

.figure__img-animation-enter.figure__img-animation-enter-active {
    opacity: 1;
    transition: all 500ms ease-in;
}

.figure__img-animation-exit {
    opacity: 1;
}

.figure__img-animation-exit.figure__img-animation-exit-active {
    transition: all 500ms ease-in;
    opacity: 0;
}

/* Caption Slide Animation */
.figure__caption-animation-enter {
    -webkit-clip-path: polygon(315px -5px, 110% -5px, 150% 110%, 420px 110%);
            clip-path: polygon(315px -5px, 110% -5px, 150% 110%, 420px 110%);
}

.figure__caption-animation-enter.figure__caption-animation-enter-active {
    -webkit-clip-path: polygon(-270px -5px, 110% -5px, 150% 110%, -160px 110%);
            clip-path: polygon(-270px -5px, 110% -5px, 150% 110%, -160px 110%);
    transition: -webkit-clip-path 1000ms ease-in;
    transition: clip-path 1000ms ease-in;
    transition: clip-path 1000ms ease-in, -webkit-clip-path 1000ms ease-in;
    transition-delay: 1000ms;
}

.figure__caption-animation-exit {
    -webkit-clip-path: polygon(-270px -5px, 110% -5px, 150% 110%, -160px 110%);
            clip-path: polygon(-270px -5px, 110% -5px, 150% 110%, -160px 110%);
}

.figure__caption-animation-exit.figure__caption-animation-exit-active {
    transition: -webkit-clip-path 1000ms ease-in;
    transition: clip-path 1000ms ease-in;
    transition: clip-path 1000ms ease-in, -webkit-clip-path 1000ms ease-in;
    -webkit-clip-path: polygon(315px -5px, 110% -5px, 150% 110%, 420px 110%);
            clip-path: polygon(315px -5px, 110% -5px, 150% 110%, 420px 110%);
}

/* Responsive Tweaks */
@media (max-width: 1300px) {
    .figure__caption-container {
        width: 300px;
    }
}

@media (max-width: 1100px) {
    .figure__caption-container {
        font-size: 1.1em;
    }
}

@media (max-width: 960px) {
    .figure__caption-container {
        font-size: 0.9em;
    }
}

@media (max-width: 830px) {
    .figure__caption {
        padding: 0;
    }

    .figure__caption-container {
        top: 50%;
        bottom: auto;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        width: auto;
        white-space: nowrap;
    }

    .figure__caption-content {
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
        right: 0.4em;
        width: 0;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
    }

    .figure__caption-content br {
        display: none;
    }

    /* Caption Slide Animation */
    .figure__caption-animation-enter {
        -webkit-clip-path: none;
                clip-path: none;
        right: -100px;
    }

    .figure__caption-animation-enter.figure__caption-animation-enter-active {
        -webkit-clip-path: none;
                clip-path: none;
        transition: right 1000ms ease-in;
        transition-delay: 1000ms;
        right: 0.4em;
    }

    .figure__caption-animation-exit {
        -webkit-clip-path: none;
                clip-path: none;
        right: 0.4em;
    }

    .figure__caption-animation-exit.figure__caption-animation-exit-active {
        -webkit-clip-path: none;
                clip-path: none;
        right: -100px;
        transition: right 1000ms ease-in;
    }
}
.home {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.home__content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 1em 2em;
    font-size: 1.5em;
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
}
/* Site-wide CSS rules */
.content-container {
  position: relative;
  z-index: 400;
}

.content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.content__article {
  position: absolute;
  z-index: 200;
  top: 150px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding-bottom: 70px;
  width: 20em;
  max-width: 90%;
  font-size: 1.5em;
  color: #fff;
}

.content__headline,
.content__text>p {
  position: static;
  display: inline;
  padding: 0.25em 0 0.355em;
  line-height: 2;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0.7em 0 0 rgba(0, 0, 0, 0.7), -0.7em 0 0 rgba(0, 0, 0, 0.7);

  /* Required for Firefox, otherwise, box-shadow is
     stripped from individual lines of a paragraph */
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
}

.content__text>p::before {
  content: '';
  display: block;
  height: 1em;
}

.content__headline {
  font-size: 1.4em;
  box-shadow: 0.5em 0 0 rgba(0, 0, 0, 0.7), -0.5em 0 0 rgba(0, 0, 0, 0.7);
}

.content__text a {
  color: inherit;
}

@media (max-width: 1100px) {
  .content__article {
    font-size: 1.3em;
  }
}

@media (max-width: 960px) {
  .content__article {
    font-size: 1.1em;
  }
}

@media (max-width: 830px) {
  .content__article {
    top: 120px;
    width: 65%;
    font-size: 0.9em;
  }
}


/* Loading animation */
.spinner {
  position: absolute;
  z-index: 1100;
  top: 50%;
  bottom: auto;
  left: 50%;
  width: 40px;
  height: 40px;
  margin: -50px 0 0 -20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  -webkit-animation: spinner 1s infinite ease-in-out;
          animation: spinner 1s infinite ease-in-out;
}

/* Tweaks for image loading spinner */
.spinner_figure {
  z-index: 100;
  top: auto;
  bottom: 135px;
  left: auto;
  right: 10px;
  margin: 0;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}

/* Page transition */
/* Stylelint rule intentionally disabled to logically group enter/exit animations */
/* stylelint-disable no-descending-specificity */
.app__animation-enter .content__article {
  -webkit-clip-path: polygon(-75px -30px,
    -5% -35px,
    -55% 100%,
    -310px 100%);
          clip-path: polygon(-75px -30px,
    -5% -35px,
    -55% 100%,
    -310px 100%);
}

.app__animation-enter.app__animation-enter-active .content__article {
  -webkit-clip-path: polygon(-75px -30px,
    165% -40px,
    100% 100%,
    -210px 100%);
          clip-path: polygon(-75px -30px,
    165% -40px,
    100% 100%,
    -210px 100%);
  transition: all 1000ms ease;
  transition-delay: 1000ms;
}

.app__animation-exit .content__article {
  -webkit-clip-path: polygon(-75px -30px,
    165% -40px,
    100% 100%,
    -210px 100%);
          clip-path: polygon(-75px -30px,
    165% -40px,
    100% 100%,
    -210px 100%);
}

.app__animation-exit.app__animation-exit-active .content__article {
  -webkit-clip-path: polygon(-75px -30px,
    -5% -35px,
    -55% 100%,
    -310px 100%);
          clip-path: polygon(-75px -30px,
    -5% -35px,
    -55% 100%,
    -310px 100%);
  transition: all 1000ms ease;
}

/* Don't animate nex/prev buttons */
.app__animation-enter .nav__arrow {
  opacity: 0;
}

.app__animation-enter.app__animation-enter-active .nav__arrow {
  opacity: 1;
}

.app__animation-exit .nav__arrow {
  opacity: 1;
}

.app__animation-exit.app__animation-exit-active .nav__arrow {
  opacity: 0;
}
body {
  color: #111;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
figure {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-size: 0.9em;
}

.visuallyhidden {
  position: absolute;
  clip: rect(0 0 0 0);
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
}
